@media print {
  html, body {
    height: 100vh; /* Use 100% here to support printing more than a single page*/
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img{
    width: 100%;
  }
  footer {
    visibility: hidden;
  }
}