.valdiate-text-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f86c6b;
}
.valdiate-text-hide {
  display: none;
}

@page {
  size: auto;
  margin: 0mm;
}

.body-iap-currency {
  box-shadow: 0px 0px 1px 2px #a0a0a029;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 20px;
}
.rouded-iap-currency {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  align-items: center;
  opacity: 0.6;
  background: var(--alert-link, #dcebfb);
}

.color-rounded-currency {
  color: var(--primary-30, #1178d4);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.total-name-currency {
  color: var(--neutral-50, #70727d);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.total-number-currency {
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* line-height:0.8 */
}

.rouded-iap-oil {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  align-items: center;
  opacity: 0.6;
  background: var(--alert-link, #fff0e6);
}

.rouded-iap-kas {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  align-items: center;
  opacity: 0.6;
  background: var(--alert-success, #ebffd0);
}

.tooltip-text::before {
  content: "";
  position: absolute;
  top: -12px;
  right: 9px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent #b9b9b9 transparent;
  box-shadow: 1 1px 2px rgba(0, 0, 0, 0.1);
}

.tooltip-text::after {
  content: "";
  position: absolute;
  top: -12px;
  right: 9px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
  box-shadow: 1 1px 2px rgba(0, 0, 0, 0.1);
}

@media print {
  .print-center {
    text-align: center;
    margin: auto;
    display: none !important;
    justify-content: center;
  }
}

.text-purple {
  color: #7b61ff;
}
.bg-purple {
  background-color: #dcebfb;
}

.btn.hover-none:hover,
.btn.hover-none:focus {
  background-color: transparent !important;
}
