// Override Core UI styling
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
    border-radius: 0.25rem;
}

.form-control.is-invalid,
.image-upload.is-invalid,
.select__control.is-invalid .select__control {
    background-color: $danger-light !important;
    color: $danger !important;
}
