button.btn-outline-primary,
button.btn-outline-success,
button.btn-outline-secondary,
button.btn-outline-warning,
button.btn-outline-danger,
button.btn-outline-info,
button.btn-outline-info {

  &:focus,
  &:active {
    color: $white !important;

    svg path {
      fill: $white;
    }
  }

  &:hover {
    color: white;

    svg path {
      fill: $white;
    }
  }

  &.disabled,
  &:disabled {
    color: white;
  }
}

.btn {
  font-weight: 600;
  border-radius: 0.25rem;
  transition: all 0.15s ease;
  padding: 0.75rem 1rem;
  margin: 0.1em 0;

  &.disabled {
    cursor: initial;
  }

  &:not(:last-child) {
    margin-right: 0.5rem;
  }
}

.btn-default {
  color: #fff;
  border-color: $blue-50;
  background-color: $blue-50;

  &:hover {
    color: #fff;
    border-color: $blue-50;
    background-color: $blue-50;
  }

  &.disabled {
    color: #fff;
    border-color: $blue-50;
    background-color: $blue-50;
  }
}

.btn-primary {
  color: #fff;

  &.disabled {
    color: #fff;
    background-color: $black-30;
    border-color: $black-30;
  }
}

.btn-secondary {
  border-color: $black-30;
  background-color: $white;

  &:hover {
    background-color: $black-20;
  }

  &.disabled {
    color: #fff;
    cursor: default;
    border-color: $black-30;
    background-color: $black-30;
  }
}

.btn-group-sm>.btn,
.btn-sm {
  font-size: 0.75rem;
  line-height: 1.5;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
}

.btn-login {
  box-shadow: 0 2px 8px 0 rgba(112, 114, 125, 0.4);
}

.btn-cancel {
  &.btn-outline-danger:hover {
    color: #EE2B2E;
    border-color: #EE2B2E;
    background-color: transparent;
    opacity: 0.8;
  }
}

.btn-edit {
  &.btn-outline-primary:hover {
    color: #0058B1;
    border-color: #0058B1;
    background-color: transparent;
    opacity: 0.8;
  }
}

.desabled-hover:hover {
  background-color: transparent !important;
  color: inherit !important;
}