// Variable overrides

// blue/Primary Color
$blue-10: #C8E4F0;
$blue-20: #A0D5EB;
$blue-30: #28A9EA; // Base
$blue-40: #299DD8; 
$blue-50: #2A91C5;
$blue-60: #0058B1;

// Purple/Secondary 1 Color
$purple-10: #7B89BA;
$purple-20: #5566A6;
$purple-30: #2E4392; // Base
$purple-40: #132878;
$purple-50: #071C68;

// Orange/Secondary 2 Color
$orange-10: #F1EBE0;
$orange-20: #F7D29F;
$orange-30: #F9CA89; // Base
$orange-40: #DAA050;
$orange-50: #CC8F3A;
$orange-60: #F29D0D;
$orange-70: #FAA918;


// Red
$red-10: #FFCFFF;
$red-20: #FF69A1;
$red-30: #eb1c54; // Base
$red-40: #D2033B;
$red-50: #9F0008;

// Black
$black-10: #f8fbfc;
$black-20: #eff3f6;
$black-30: #dcdee3;
$black-40: #9c9da6;
$black-50: #70727d;
$black-60: #434755;
$black-70: #151823;

//yellow
$yellow: #ff7d1d;

$white: #ffffff;
$primary: $blue-60;
$secondary-dark: #70727D;
$secondary-light: #EFF3F6;
$secondary-purple: $purple-30;
$secondary-orange: $orange-30;

$success:  #70AD1D;
$success-dark: #6FAA1E;
$success-light: #EBFFD0;
$danger:  #EE2B2E;
$danger-light: #FFEDEE;
$warning: $orange-70;
$warning-dark: #FF7D1D;
$warning-light: #FFF0E6;
$info: $blue-30;
$info-dark: #1178D4;
$info-light: #DCEBFB;
$info-purple: #7B61FF;
$light: $black-30;
$dark: $black-70;
$text-muted: $black-40;

$color-lists: (
  blue-10: $blue-10,
  blue-20: $blue-20,
  blue-30: $blue-30,
  blue-40: $blue-40,
  blue-50: $blue-50,
  blue-60: $blue-60,
  purple-10: $purple-10,
  purple-20: $purple-20,
  purple-30: $purple-30,
  purple-40: $purple-40,
  purple-50: $purple-50,
  orange-10: $orange-10,
  orange-20: $orange-20,
  orange-30: $orange-30,
  orange-40: $orange-40,
  orange-50: $orange-50,
  red-10: $red-10,
  red-20: $red-20,
  red-30: $red-30,
  red-40: $red-40,
  red-50: $red-50,
  black-10: $black-10,
  black-20: $black-20,
  black-30: $black-30,
  black-40: $black-40,
  black-50: $black-50,
  black-60: $black-60,
  black-70: $black-70,
  "yellow": $yellow,
  "white": $white,
  primary: $primary,
  secondary-purple: $secondary-purple,
  secondary-orange: $secondary-orange,
  success: $success,
  success-dark: $success-dark,
  success-light: $success-light,
  danger: $danger,
  danger-light: $danger-light,
  warning: $warning,
  warning-dark: $warning-dark,
  info: $info,
  info-dark: $info-dark,
  info-light: $info-light,
  info-purple: $info-purple,
  light: $light,
  dark: $dark
);


@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@600&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
// $font-family-montserrat: 'Montserrat', sans-serif;
$font-family-rubik: 'Rubik', sans-serif;
$font-family-base: $font-family-rubik !default;

$font-weight-light: 300 !default;
$font-weight-regular: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-bold: 700 !default;

$font-weight-base: $font-weight-regular !default;