.badge {
    padding: 5px 8px !important;
    font-family: "Nunito", sans-serif !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    line-height: 14px !important;

    &.badge-blue {
        color: $blue-60 !important;
        background-color: $info-light !important;
    }

    &.badge-danger {
        color: $danger !important;
        background-color: $danger-light !important;
    }

    &.badge-grey {
        color: $black-50 !important;
        background-color: $black-10 !important;
    }

    &.badge-info {
        color: $info-dark !important;
        background-color: $info-light !important;
    }

    &.badge-primary {
        color: $white !important;
        background-color: $primary !important;
    }

    &.badge-purple {
        color: $info-purple !important;
        background-color: $info-light !important;
    }

    &.badge-secondary {
        color: $secondary-dark !important;
        background-color: $secondary-light !important;
    }

    &.badge-success {
        color: $success-dark !important;
        background-color: $success-light !important;
    }

    &.badge-warning {
        color: $warning-dark !important;
        background-color: $warning-light !important;
    }
}
