/* Font Style */
// @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');

/* Variables */
$blue-30: #76afec;
$blue-40: #2a93d6;
$blue-50: #105588;

$red-50: #eb1c54;

$green-50: #3a9d5d;

body {
  background: white;
  color: $black-70;
}

#nprogress .bar {
  background: $blue-30;
}

.background-login {
  background-image: url("../image/bg-login.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.main .container-fluid {
  padding: 3.5rem 3rem 0 3rem;
}

.bg-white {
  background-color: #fff !important;
}

.text-color-gray {
  color: #9c9da6 !important;
}

.bg-black-10 {
  background-color: $black-10 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

.subtitle {
  h4 {
    line-height: 1.125;
  }

  span {
    line-height: 1.14;
    color: $black-40;
  }
}

/* ------------------ SIDEBAR ------------------ */
html:not([dir="rtl"]) .sidebar {
  margin-left: -250px;
}

html:not([dir="rtl"]) .sidebar-md-show.sidebar-fixed .main,
html:not([dir="rtl"]) .sidebar-md-show.sidebar-fixed .app-footer,
html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main,
html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
  margin-left: 250px;
}

.sidebar-fixed .sidebar {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  width: 250px;
}

.sidebar-minimized {
  .sidebar {
    .nav-item {
      margin-top: 8px;
      margin-bottom: 8px;
    }

    .nav-dropdown-items .nav-item {
      padding-top: 8px;
      margin: 0px;
    }
  }

  .sidebar-minimizer {
    margin-left: 0.5rem;

    &:before {
      left: -8px;
    }
  }
}

.sidebar {
  &-minimizer {
    position: relative;
    height: 23px;
    width: 1.5em;
    padding: 0 12px;
    margin-top: 0 !important;
    margin-left: 6rem;
    border: none;
    background: url("../image/ic_headline.png");

    &::before {
      content: "";
      position: absolute;
      top: 4px;
      left: -46px;
      width: 1px;
      height: 16px;
      background-color: $black-30;
    }
  }

  .sidebar-footer {
    background: none;
    color: $black-40;
    text-align: center;
  }

  .sidebar-nav {
    width: 250px !important;
  }

  .nav {
    width: 250px !important;
  }

  .nav-link {
    color: $black-70;
    font-weight: 500;
    margin: 8px 12px;
    display: flex;
    align-items: center;

    .nav-icon {
      margin-top: -2px;
      flex-shrink: 0;
    }

    &.active {
      color: #fff !important;
      background: $primary !important;
      border-radius: 4px;

      // box-shadow: 0 0 2rem 0 rgba(136,152,170,.15);
      .nav-icon {
        color: $white;
      }

      &:hover {
        background-color: $primary !important;
        color: $white !important;

        .nav-icon {
          color: $white !important;
        }
      }
    }

    &:hover {
      background-color: $black-30 !important;
      color: $black-70 !important;
      border-radius: 4px;

      .nav-icon {
        color: $black-50 !important;
      }
    }
  }

  .nav-dropdown {
    &.open {
      background: #fff !important;

      >.nav-dropdown-toggle::before {
        top: 60%;
        background-image: none !important;
        transform: rotate(-180deg);
      }

      .nav-link {
        color: $black-70;

        &:hover {
          color: #fff;
        }
      }
    }

    .nav-dropdown-items {
      .nav-link {
        font-weight: 400;

        &.active {
          font-weight: 500;
        }
      }
    }

    .nav-dropdown-toggle::before {
      top: 40%;
      content: "\f107";
      font-family: "FontAwesome";
      background-image: none !important;
    }
  }
}

.sidebar-minimized .sidebar .nav-dropdown-items .nav-item {
  background-color: #fff;
}

.img-logo {
  width: 136px;
}

/* ------------------ LOGIN PAGE  ------------------*/
.card-welcome-home {
  // background-image: linear-gradient(0deg, #fbab7e 0%, #f7ce68 100%);
  margin: 0px auto;
}

/* ------------------TABS-------------------*/

.nav-tabs {
  border: none;

  .nav-link {
    color: $black-40 !important;
    font-size: 1rem;

    &.active {
      border: none;
      border-bottom: 3px solid $primary;
      font-weight: 700;
      color: $black-70 !important;
      background: none;

      &:hover {
        border-bottom: 3px solid $primary;
        color: $black-70;
      }
    }

    &:hover {
      border-color: transparent;
      color: $black-70 !important;
    }
  }
}

.tab-content {
  border: none;
  background: none;

  .tab-pane {
    padding: 0;
  }
}

/* ------------------ HEADER ------------------ */
.app-header .navbar-brand {
  width: 200px;
}

.header-fixed .app-header {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
}

.app-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  height: 70px;

  .navbar-toggler,
  .navbar-toggler:hover {
    .navbar-toggler-icon {
      background-image: url("../image/ic_headline.png");
    }
  }
}

/* ------------------ FOOTER ------------------ */
.app-footer {
  background-color: transparent;
  border: none;
  color: #151823 !important;
}

/* ----------------- ACCORDION ----------------- */
.accordion {
  .accordion-header {
    font-weight: normal !important;
    border-radius: calc(0.375rem - 1px) !important;

    &:hover {
      cursor: pointer;
    }

    .accordion-arrow {
      float: right;
      font-size: 24px;
      transition: transform 0.3s, -webkit-transform 0.3s;
    }

    &.open {
      .accordion-arrow {
        transform: rotate(-180deg);
        -webkit-transform: rotate(-180deg);
      }
    }
  }

  .accordion-body {
    padding-top: 0 !important;
    color: $primary;
    font-size: 16px;

    ul {
      font-size: 24px;
    }

    .btn-link {
      font-weight: 400;
      font-size: 16px;
      padding: 0;
    }
  }

  &.open {
    .accordion-arrow {
      transform: rotate(-180deg);
      -webkit-transform: rotate(-180deg);
    }
  }
}

/* ----------------- BREADCRUMB ----------------- */
.breadcrumb-custom {
  color: $black-70;
  font-size: 12px;
  font-weight: 500;

  .breadcrumb-link {
    color: $black-40;
    font-weight: 400;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  .breadcrumb-arrow {
    padding: 0 14px;
    font-weight: 400;
    color: $black-40;
  }
}

/* ------------------ FORM ------------------ */
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #9c9da6 !important;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #9c9da6 !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #9c9da6 !important;
}

.form-control {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  color: #151823;
  border: 1px solid $black-30;
  border-radius: 0.25rem;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
  height: calc(1.5em + 1.25rem + 5px);

  &:focus {
    border-color: $primary;
    box-shadow: 0 3px 9px rgba(50, 50, 9, 0),
      3px 4px 8px rgba(94, 114, 228, 0.1);
  }
}

.form-control-lg {
  font-size: 1rem !important;
  height: calc(2.25em + 1.25rem + 5px);
}

.form-control-sm {
  height: calc(0.45em + 1.25rem + 5px);
}

.form-control-label {
  font-size: 0.875rem;
  font-weight: 600;
  color: #525f7f;
}

.form-control-label-optional {
  color: rgba(0, 0, 0, 0.45);
  margin-left: 4px;
  font-size: 12px;
}

.col-form-label {
  padding-top: 0;
  color: $black-50;
}

.input-search {
  display: flex;
  position: relative;

  i {
    position: absolute;
    top: 30%;
    left: 18px;
    font-size: 18px;
  }

  .append {
    position: absolute;
    right: 0;
    top: unset;
    font-size: 18px;
    height: 38px;
    width: 38px;
    margin: 4px;
    padding: 0;

    i {
      position: relative;
      left: unset;
      top: unset;
    }
  }
}

/* ------------------ SELECT ------------------ */
.css-1pahdxg-control:hover {
  box-shadow: 0 3px 9px rgba(50, 50, 9, 0), 3px 4px 8px rgba(94, 114, 228, 0.1);
}

.select__control {
  border-color: $black-30 !important;
}

.select__indicator-separator {
  background: none !important;
}

.select__value-container {
  padding: 8px !important;
}

.select__multi-value {
  background-color: $black-20 !important;
  border-radius: 1rem !important;
  font-size: 14px;

  &__remove {
    color: $black-40;

    &:hover {
      border-radius: 1rem;
    }
  }
}

.select__menu {
  z-index: 11 !important;
}

/* ------------------ CHECKBOX ------------------ */

.custom-checkbox {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 4px;

  &.select-all {
    padding-left: 1rem;
    border-left: 1px solid $black-30;
  }

  label {
    position: relative;
    padding-left: 30px;
    font-weight: normal;
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: -6px;
      width: 1.5rem;
      height: 1.5rem;
      transition: transform 0.28s ease;
      border-radius: 4px;
      border: 1px solid $black-40;
    }
  }

  input[type="checkbox"] {
    width: auto;
    opacity: 0.00000001;
    position: absolute;
    left: 0;
    margin-left: -20px;

    &:checked~label {
      &:before {
        transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        background-color: $orange-60;
        border: 2px solid $orange-60;
      }

      &:after {
        content: "";
        position: absolute;
        left: 6px;
        top: 1px;
        height: 7px;
        width: 12px;
        border-left: 2px solid $white;
        border-bottom: 2px solid $white;
        transform: rotate(-45deg);
      }
    }

    &:focus+label::before {
      outline: 0;
    }

    &:disabled+label::before {
      background-color: #e4e7ea;
    }
  }

  &.checkbox-inactive {
    input[type="checkbox"]:disabled+label {
      opacity: 0.5;
      cursor: default;

      &::before {
        background-color: transparent;
      }
    }
  }
}

.checkbox-pill {
  display: inline-block;
  width: auto;

  &:not(:last-child) {
    margin-right: 1rem;
  }

  label {
    border-radius: 16px;
    background-color: $black-20;
    transition: background-color 0.3s ease;
    height: 31px;
    font-size: 14px;
    color: #151823;
    padding-right: 10px;
    padding-top: 5px;
    margin-bottom: 0;

    &:before {
      background-color: $white;
      width: 1rem;
      height: 1rem;
      border-radius: 3px;
      left: 4px;
      top: 2px;
      margin: 5px;
    }
  }

  input[type="checkbox"] {
    width: auto;
    opacity: 0.00000001;
    position: absolute;
    left: 0;
    margin-left: -20px;

    &:checked~label {
      background-color: $primary;
      color: $white;

      &:before {
        background-color: $white;
        border: 2px solid $white;
      }

      &::after {
        content: "";
        position: absolute;
        left: 13px;
        top: 12px;
        height: 5px;
        width: 9px;
        border-left: 2px solid $primary;
        border-bottom: 2px solid $primary;
        transform: rotate(-45deg);
      }
    }
  }
}

/* ------------------ RADIO BUTTON ------------------ */
.custom-radio {
  width: 100%;
  position: relative;
  display: block;

  label {
    position: relative;
    padding-left: 30px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: -2px;
      margin: 5px;
      width: 1rem;
      height: 1rem;
      transition: transform 0.28s ease;
      border-radius: 50%;
      border: 1px solid #dee2e6;
    }
  }

  input[type="radio"] {
    width: auto;
    opacity: 0.00000001;
    position: absolute;
    left: 0;
    margin-left: 10px;

    &:checked~label {
      &:before {
        transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        background-color: $white;
        border: 5px solid $blue-40;
      }

      &:after {
        transform: rotate(-45deg) scale(1);
      }
    }

    &:focus+label::before {
      outline: 0;
    }
  }
}

.list-radio {
  width: 100%;
  position: relative;
  display: block;

  &:not(:last-child) {
    label {
      margin-bottom: 0.75rem;
    }
  }

  label {
    position: relative;
    width: 100%;
    padding: 0.5rem;
    font-weight: normal;
    color: $black-50;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0.25rem;
      margin: 5px;
      width: 1rem;
      height: 1rem;
      transition: transform 0.28s ease;
      border-radius: 50%;
      border: 1px solid #dee2e6;
    }
  }

  input[type="radio"] {
    width: auto;
    opacity: 0.00000001;
    position: absolute;
    left: 0;
    margin-left: 10px;

    &:checked~label {
      background-color: $black-20;

      &:after {
        transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        background-color: $white;
        border: 5px solid $primary;
      }

      &:before {
        transform: rotate(-45deg) scale(1);
      }
    }

    &:disabled~label {
      &:after {
        border: 5px solid $black-50;
      }
    }

    &:focus+label::after {
      outline: 0;
    }
  }
}

/* ------------------ CUSTOM SWITCH ------------------ */
.custom-switch {
  position: relative;
  display: inherit;
  display: inline-block;
  width: 40px;
  height: 1.5rem;
  margin: 0;

  input:checked+.custom-control-label {
    background-color: $blue-40;
  }

  .custom-control-label {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
    border: 1px solid $black-30;
    border-radius: 34px !important;
    background-color: $black-30;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 22px;
      height: 22px;
      content: "";
      transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      border-radius: 50% !important;
      background-color: $white;
      border-color: $white;
    }

    &:after {
      font-family: inherit;
      font-size: 0.75rem;
      font-weight: 600;
      position: absolute;
      top: 0.2em;
      right: 0;
      display: block;
      min-width: 1.66667rem;
      margin: 0 4.8em;
      transition: all 0.15s ease;
      text-align: center;
      color: #ced4da;
      background-color: transparent;
    }
  }

  .custom-control-input {
    &:checked~.custom-control-label {
      &:before {
        transform: translateX(16px);
        background-color: $blue-40;
      }

      &:after {
        margin: 0 2.2em;
        color: $blue-40;
      }
    }
  }
}

/* switch success */
.custom-switch-success input:checked+.custom-control-label {
  border-color: $success;
}

.custom-switch-success input:checked+.custom-control-label::before {
  border-color: $success;
  background-color: $success !important;
}

.custom-switch-success input:checked+.custom-control-label::after {
  color: $success !important;
}

/* switch success */
.custom-switch-success input:checked+.custom-control-label {
  background-color: $success;
}

.custom-switch-success input:checked+.custom-control-label::before {
  border-color: $white;
  background-color: $white !important;
}

.custom-switch-success input:checked+.custom-control-label::after {
  color: $success !important;
  background-color: transparent !important;
}

/* switch info */
.custom-switch-info input:checked+.custom-control-label {
  border-color: $blue-30;
}

.custom-switch-info input:checked+.custom-control-label::before {
  border-color: $blue-30;
  background-color: $blue-40 !important;
}

.custom-switch-info input:checked+.custom-control-label::after {
  color: $blue-30 !important;
}

/* switch danger */
.custom-switch-danger input:checked+.custom-control-label {
  border-color: $red-50;
}

.custom-switch-danger input:checked+.custom-control-label::before {
  border-color: $red-50;
  background-color: $red-50 !important;
}

.custom-switch-danger input:checked+.custom-control-label::after {
  color: $red-50 !important;
}

.custom-switch-success,
.custom-switch-info,
.custom-switch-danger {
  input:disabled+.custom-control-label {
    border-color: $black-50;
    background-color: $black-50;
  }

  input:disabled+.custom-control-label::before {
    border-color: $white;
    background-color: $white !important;
  }
}

/* ------------------ CARD ------------------ */
.card {
  margin-bottom: 24px;
  border: none;
  box-shadow: 0 1px 4px 0 rgba(55, 55, 59, 0.2);

  .table {

    th,
    td {
      padding-left: 1.5rem;
    }
  }

  .card-header {
    margin-bottom: 0;
    padding: 1.5rem;
    border: none;
    font-size: 16px;
    font-weight: 700;
    background-color: $white;

    &:first-child {
      border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
    }
  }

  .card-body {
    padding: 1.5rem;
  }

  .card-footer {
    background-color: $white;
    border: none;
  }
}

.card-login {
  border-radius: 16px;
  box-shadow: 0 2px 8px 0 rgba(112, 114, 125, 0.4);

  .card-body {
    padding: 60px;
  }
}

.card-authorization {
  hr:last-child {
    display: none;
  }
}

.card-information {
  border-radius: 16px;
  padding: 16px 18px;
  display: flex;
  flex-direction: column;

  min: {
    height: 152px;
    width: min-content;
  }

  justify-content: space-between;

  span {
    font-size: 16px;
    color: $black-50;
  }

  div {
    font-size: 28px;
    line-height: 1.21;
    cursor: default;
  }
}

.card-oil-amount {
  border-radius: 16px;
  padding: 24px 22px;
  height: 100%;

  &>div {
    height: 100%;
  }

  &.primary {
    display: flex;
    align-items: center;
  }

  .amount {
    margin-top: auto
  }
}

/* ------------------ TABLE ------------------ */
.table {
  margin-bottom: 0;
  color: #525f7f;

  thead {
    box-shadow: 0px 1px 4px rgba(112, 114, 125, 0.2);

    th {
      border: none;
      background: none;
      vertical-align: middle;
      border-color: #DCDEE3;

      &.hasChildren {
        text-align: center;
        position: relative;

        &::before {
          content: "";
          width: 90%;
          height: 1px;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          background-color: #DCDEE3;
        }
      }


      a {
        color: $primary;
        text-decoration: none;
      }
    }
  }

  tbody {

    th,
    td {
      border-top: none;
    }

    tr:nth-child(odd) {
      background-color: $black-20;
    }
  }
}

table table {
  thead tr {
    background-color: $white !important;
  }

  tbody tr {
    &:nth-child(odd) {
      background-color: $black-20 !important;
    }

    &:nth-child(even) {
      background-color: $white !important;
    }
  }
}

.percentage {
  &-red {
    color: $red-50;
  }

  &-green {
    color: $green-50;
  }

  &-orange {
    color: orange;
  }
}

.scrollable-table {
  .table-responsive {
    height: 200px;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    table {
      overflow: auto;

      thead {
        position: sticky;
        top: 0;
        background-color: $white;
      }

      tbody tr td {
        &:nth-child(2) {
          white-space: nowrap;
        }
      }
    }
  }
}

/* ------------------ PAGINATION ------------------ */
.page-link {
  border-radius: 4px !important;
  margin: 0 3px;
  border: 1px solid #dee2e6;
  color: $primary;
}

.page-item.active .page-link {
  z-index: 0;
  background-color: $primary;
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.size-selector .select-pagination__control:hover {
  background-color: #e4e7ea;
  border-color: #c8ced3;
}

ul.pagination {
  margin-bottom: 0;
}

.scroll-to-top {
  z-index: 99;
  right: 40px;
  bottom: 50px;
  margin: 0;
}

/* ------------------ BADGE ------------------ */
.badge {
  padding: 0.5rem;
  font-size: 14px;

  &:not(:last-child) {
    margin-right: 1rem;
  }
}

/* ------------------ DATEPICKER ------------------ */
.react-datepicker {
  border: none !important;
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1),
    0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);

  &-wrapper {
    width: 100%;

    &:has(.no-icon) {
      .react-datepicker__input-container {
        &:before {
          content: "";
        }
      }
    }
  }

  &__header {
    border-bottom: none !important;
  }

  &__close-icon {
    right: 36px !important;
  }

  &__input-container {
    &:before {
      color: $black-40;
      position: absolute;
      top: 12px;
      right: 12px;
      font-family: "FontAwesome";
      content: "\f073";
    }
  }

}

.react-datepicker-popper {
  z-index: 15 !important;
}

.pagination .form-control {
  height: calc(0.7em + 1.25rem + 5px);
  padding: 0.375rem 0.75rem;
}

.page-link {
  border: 1px solid #eff3f6;
}

.page-item.disabled .page-link {
  border: 1px solid #eff3f6;
}

/* ------------------ MODAL ------------------ */
.modal-content {
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.2), 0 5px 15px rgba(0, 0, 0, 0.17);
}

/* ------------------ MODAL CONFIRMATION ------------------ */
.modal-header,
.modal-footer {
  border: none;
}

.modal-body,
.modal-header,
.modal-footer {
  padding: 1.5rem;
}

.modal-header {
  .modal-title {
    font-size: 24px;
    font-weight: 500;
  }

  .close {
    font-weight: 300;
    font-size: 36px;
    padding: 0;
    margin: -0.5px 0 0 0;
  }
}

.modal-body.confirmation-modal {
  padding: 1.5rem;
}

.modal-body.confirmation-modal .confirmation-header {
  display: flex;
  flex-direction: column;

  .confirmation-header-title {
    font-size: 1.5rem;
    color: $black-70;
    margin: 0 0 1.5rem;
    font-weight: 500;
  }
}

.confirmation-header .confirmation-header-icon {
  position: relative;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 1.25em auto 1.875em;
  border: 0.25em solid transparent;
  border-radius: 50%;
  line-height: 5em;
  cursor: default;
  box-sizing: content-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  zoom: normal;
  color: $blue-40;
  border: 0.2em solid transparent;
  border-radius: 50%;
  border-color: $blue-30;
  display: flex;
  align-items: center;

  .confirmation-icon-text {
    font-size: 3.75em;
  }
}

/* ------------------ INPUT PASSWORD ------------------ */
.input-password {
  border-radius: 4px !important;
}

.icon-password {
  display: inline-block;
  position: absolute;
  top: 8px;
  right: 12px;
  z-index: 4;
  padding: 7px;
  border-radius: 999px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

/* ----------CHART--------- */
.chart-legend-card {
  display: inline-block;
  width: 100%;
  background-color: $black-10;
  font-weight: 500;

  .chart-legend-circle {
    position: relative;
    display: inline-block;
    top: 4px;
    width: 16px;
    height: 16px;
    margin-right: 12px;
    border-radius: 8px;

    &.bg-blue {
      background-color: $blue-40 !important;
    }

    &.bg-green {
      background-color: #07d2ae !important;
    }

    &.bg-orange {
      background-color: #fb734e !important;
    }
  }

  .text-blue {
    color: $blue-40 !important;
  }

  .text-green {
    color: #07d2ae !important;
  }

  .text-orange {
    color: #fb734e !important;
  }
}

.tooltip-chart-box {
  position: relative;
  background-color: $black-60;
  border-radius: 4px;
  text-align: center;
  color: $white;
  padding: 12px 16px;

  .tooltip-date {
    font-weight: 400;
    margin: 0;
    padding: 0;
    font-size: 8px;
    line-height: 10px;
    margin-bottom: 4px;
  }

  .tooltip-Value {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
  }
}

.tooltip-sd {
  .tooltip-inner {
    background-color: white;
    color: black;
    box-shadow: 0px 1px 4px 0px rgba(112, 114, 125, 0.40);
  }

  [x-placement="top"] {
    .arrow {
      &::before {
        border-top-color: white !important;
      }
    }
  }

  [x-placement="bottom"] {
    .arrow {
      &::before {
        border-bottom-color: white !important;
      }
    }
  }

  [x-placement="right"] {
    .arrow {
      &::before {
        border-right-color: white !important;
      }
    }
  }

  [x-placement="left"] {
    .arrow {
      &::before {
        border-left-color: white !important;
      }
    }
  }
}

/* ---------Special Component--------- */
.action-more-dropdown {
  background: #ffffff;
  border: 1px solid #dcdee3;
  box-shadow: 0px 1px 4px rgba(112, 114, 125, 0.4);
  border-radius: 4px;
  padding: 8px;

  .dropdown-item {
    border-bottom: none;
    padding: 8px 20px;
  }

  a:hover {
    text-decoration: none;
  }
}

/* ---------DASHBOARD--------- */
.messages-sent {
  display: flex;
  flex-wrap: wrap;
  padding-top: 0px;

  &-countbox {
    width: 100px;
    height: 100px;
    background: #ebf7fd;
    color: $primary;
    text-align: center;
    padding: 1rem;
    border-radius: 4px;
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }

  &-title {
    display: inline-block;
    width: 212px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.icon-managed-wrapper {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  flex-wrap: wrap;

  i {
    margin-right: 0.5rem;
    font-size: 42px;
    color: $primary;
  }
}

/*-------- Custom Notification -------*/
.notification-container {
  width: auto !important;
  max-width: 550px;

  .notification-error {
    background: #ffedee;
    color: #ee2b2e;
    border: 1px solid #ee2b2e;
    box-shadow: none;
  }

  .notification-success {
    background: #ebffd0;
    color: #47b121;
    border: 1px solid #47b121;
    box-shadow: none;
  }
}

/* --------Custom Class------ */
.container-card-transaction {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
  gap: 16px;

  .invalid {
    background-color: #ffedee;
    border: 2px solid #e62d5c;
  }

  .selected {
    border: 2px solid #3f9eff !important;
  }
}

.container-card-transaction-group {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
  gap: 16px;

  .invalid {
    background-color: #ffedee;
    border: 2px solid #e62d5c;
  }
}

.text-orange {
  color: #faa918;
}

.text-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

@media (max-width: 414px) {
  .container-card-transaction-group {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
}

/* --------IMAGES & ICON------ */
.icon-link:hover {
  cursor: pointer;
  color: $primary;
}

/* ---------MEDIA QUERY----------- */
@media (min-width: 576px) {
  .modal-dialog-confirmation {
    max-width: 422px;
  }

  .modal-sm {
    max-width: 386px;
  }

  .modal-lg {
    max-width: 800px;
  }

  .modal-xl {
    max-width: 936px;
  }

  .enlarge-md {
    max-width: 530px;
  }
}

@media (max-width: 576px) {
  html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
    display: none;
  }

  .sidebar-show .main,
  .aside-menu-show .main {
    margin-left: 0px !important;
  }

  .custom-checkbox {
    &.select-all {
      display: inline-block;
      border-left: none;
      padding-left: 0;
    }
  }

  .page-item:not(:first-child):not(:last-child):not(:nth-last-child(2)):not( :nth-child(2)) {
    display: none;
  }

  .page-item.active {
    display: block !important;
  }

  .container-card-transaction {
    &.less-four {
      grid-template-columns: auto;
    }
  }
}

small,
.small {
  font-size: 12px;
}

.spinner-border-xl {
  width: 8rem;
  height: 8rem;
  border-width: 0.8rem;
}

@media (max-width: 991.98px) {
  .main .container-fluid {
    padding: 1.5rem;
  }

  .app-header .navbar-brand {
    display: none;
  }

  .app-footer {
    align-self: center;
  }
}

@media only screen and (min-width: 992px) {

  html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main,
  html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .app-footer,
  html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main,
  html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
    margin-left: 250px;
  }
}

@media (min-width: 992px) {
  .sidebar {
    .sidebar-header {
      display: none;
    }

    .nav {
      padding-top: 24px;
    }
  }

  .sidebar-minimized .sidebar {
    .nav-link.nav-dropdown-toggle.open {
      color: $white;
    }

    .nav-dropdown-items .nav-item:hover>.nav-link {
      background-color: $white;
    }

    .nav-dropdown-items .nav-item>.nav-link {
      padding-left: 1rem;
    }

    .nav,
    .sidebar-nav {
      width: 50px !important;
    }
  }
}

.moda-password-title {
  font-size: 1rem;
  margin-bottom: 1rem;
  font-weight: 500;
  line-height: 18px;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
}

.is-invalid .select__control {
  border-color: #EE2B2E !important;
}

@media (max-width: 1200px) {
  .messages-sent-countbox {
    width: 100%;
    margin-bottom: 1rem;
  }
}

.text-optional-grey {
  font-size: 10px;
  color: #9c9da6;
  font-weight: 400;
}

/*-------- Custom Input Counter -------*/

.wrapper-input-counter {
  position: relative;

  .group-counter {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 10;
    height: 100%;
    justify-content: center;
    right: 4px;
  }

  .button-counter-up,
  .button-counter-down {
    background: none;
    border: none;
    font-size: 10px;

    &:focus {
      outline: none;
    }
  }
}