.d1 {
  font-size: 28px;
  line-height: 30px;
  font-weight: 500;
  color: $black-70;
}
.d2 {
  font-size: 24px;
  line-height: 26px;
  font-weight: 500;
  color: $black-70;
}

.b1 {
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  color: $black-70;
}
.b2 {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: $black-70;
}
.b3 {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: $black-50;
}
.b4 {
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  color: $black-50;
}

.p1 {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: $black-70;
}
.p2 {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: $black-70;
}
.font-weight-medium {
  font-weight: $font-weight-medium;
}

.text-10 {
  font-size: 10px;
}
.text-12 {
  font-size: 12px;
}
.text-16 {
  font-size: 16px;
}
.text-20 {
  font-size: 20px;
}
.text-24 {
  font-size: 24px;
}
.text-36 {
  font-size: 36px;
}

@each $color, $value in $color-lists {
  .text-#{$color} {
    color: $value !important;
  }
}